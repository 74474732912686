<template>
  <v-row
    align="center"
    justify="center"
  >
    <v-col
      cols="12"
      sm="8"
      md="4"
    >
      <v-card class="elevation-12">
        <v-toolbar
          flat
        >
          <v-toolbar-title>Login to Meavitae Templates</v-toolbar-title>
        </v-toolbar>
        <validation-observer ref="form">
          <v-form
            @submit.prevent="submit"
          >
            <v-card-text>
              <validation-provider v-slot="{ errors, valid }" rules="required|email">
                <v-text-field
                  v-model="emailAddress"
                  :error-messages="errors"
                  :success="valid"
                  label="Email Address"
                  prepend-icon="mdi-account"
                  required
                />
              </validation-provider>

              <validation-provider v-slot="{ errors, valid }" rules="required">
                <v-text-field
                  v-model="password"
                  :error-messages="errors"
                  :success="valid"
                  label="Password"
                  prepend-icon="mdi-eye"
                  type="password"
                />
              </validation-provider>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                type="submit"
              >
                Login
              </v-btn>
            </v-card-actions>
          </v-form>
        </validation-observer>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import attemptApiCall from '../utils/attemptApiCall'
import getJWTFromIDP from '../apis/idp/getJWTFromIDP'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { getUserDetailsFromJwt } from '../utils'

export default {
  name: 'Login',

  components: {
    ValidationObserver,
    ValidationProvider
  },

  data () {
    return {
      emailAddress: '',
      password: ''
    }
  },

  methods: {
    async submit () {
      const formIsValid = await this.$refs.form.validate()

      if (formIsValid) {
        try {
          const jwt = await attemptApiCall(
            getJWTFromIDP,
            {
              email: this.emailAddress,
              password: this.password
            }
          )

          const { userId, userRole } = getUserDetailsFromJwt(jwt)

          this.$store.commit('setUser', { userId, userRole })

          sessionStorage.setItem('jwt', jwt)

          this.emailAddress = this.password = ''
          this.$nextTick(() => {
            this.$refs.form.reset()
          })
        } catch (error) {
          console.log(error)

          sessionStorage.removeItem('jwt')
          this.$store.commit('unsetUser')
        }
      }
    }
  }
}

</script>
