<template>
  <v-alert
    prominent
    type="error"
    data-test="error-page"
  >
    <template v-if="errorCode === 400">
      <h1
        class="mb-5"
        data-test="error-title"
      >
        Incomplete
      </h1>

      <p>Sorry, an error has been returned from our server. The action you were trying to carry out could not be completed. Reload the page and try to carry out that action again.</p>

      <p>If this error persists, then raise a support ticket with us detailing what you were doing at the time it occurred.</p>
    </template>

    <template v-else-if="errorCode === 401">
      <h1
        class="mb-5"
        data-test="error-title"
      >
        Unauthorized
      </h1>

      <p>An unauthorized error has been returned from our server.</p>

      <p>Please raise a support ticket with us detailing what you were doing at the time it occurred.</p>
    </template>

    <template v-else-if="errorCode === 404">
      <h1
        class="mb-5"
        data-test="error-title"
      >
        Not Found
      </h1>

      <p>Sorry, the item you were looking for could not be found on our server. Reload the page and try to complete the action you were doing again.</p>

      <p>If this error persists, then raise a support ticket with us detailing what you were doing at the time it occurred.</p>
    </template>

    <template v-else-if="errorCode === 500">
      <h1
        class="mb-5"
        data-test="error-title"
      >
        Internal Server Error
      </h1>

      <p>Sorry, our server has encountered an internal error. Reload the page and try to complete the action you were doing again.</p>

      <p>If this error persists, then raise a support ticket with us detailing what you were doing at the time it occurred.</p>
    </template>

    <template v-else-if="errorCode === 503">
      <h1
        class="mb-5"
        data-test="error-title"
      >
        Service Unavailable
      </h1>

      <p>Sorry, we have encountered an error communicating with our server. Either the server is unavailable, or the attempted connection to it has timed out. Reload the page and try to complete the action you were doing again.</p>

      <p>If this error persists, then raise a support ticket with us detailing what you were doing at the time it occurred.</p>
    </template>

    <template v-else>
      <h1
        class="mb-5"
        data-test="error-title"
      >
        Error
      </h1>

      <p>Sorry, an error has occurred. Please reload the page and try to complete the action you were doing.</p>

      <p>If this error persists, then raise a support ticket with us detailing what you were doing at the time it occurred.</p>
    </template>
  </v-alert>
</template>

<script>
export default {
  name: 'ErrorMessage',

  data () {
    return {
      errorCode: this.$store.state.errorCode
    }
  }
}
</script>
