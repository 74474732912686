'use strict'

import arrayStoreFunctions from './arrayStoreFunctions'
import attemptApiCall from '../utils/attemptApiCall'
import getArrayFromTms from '../apis/tms/utils/getArrayFromTms'
import makeTmsDeleteRequest from '../apis/tms/requests/makeTmsDeleteRequest'
import persistObjectToTms from '../apis/tms/utils/persistObjectToTms'

export default {
  namespaced: true,
  state: [],
  getters: { ...arrayStoreFunctions.getters },
  actions: {
    getTemplateListFromTmsApi: async ({ dispatch }) => {
      return attemptApiCall(
        getArrayFromTms,
        { jwt: sessionStorage.getItem('jwt') }
      )
    },

    setObjectToApi: async ({ state, commit }, { id, object }) => {
      return attemptApiCall(
        persistObjectToTms,
        {
          id,
          jwt: sessionStorage.getItem('jwt'),
          object
        }
      )
    },

    deleteObjectFromApi: async ({ dispatch }, { id }) => {
      return attemptApiCall(
        makeTmsDeleteRequest,
        {
          id,
          jwt: sessionStorage.getItem('jwt')
        }
      )
    }
  },
  mutations: { ...arrayStoreFunctions.mutations }
}
