'use strict'

import axios from 'axios'
import createEndPoint from '../utils/createEndPoint'

const { VUE_APP_TMS_URL } = process.env

export default async ({ id, jwt }) => {
  if (!id) throw new Error('id not provided')
  if (!jwt) throw new Error('jwt not provided')

  const [type, locale, version] = id.split(':')
  const endPoint = createEndPoint({ type, locale, version })

  return axios({
    method: 'delete',
    headers: { Authorization: jwt },
    url: `${VUE_APP_TMS_URL}/${endPoint}`,
    timeout: 8000
  })
}
