<template>
  <v-layout>
    <v-row>
      <v-col>
        <error-message v-if="showErrorComponent" />
        <document-templates-table v-else />
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
import ErrorMessage from '../components/ErrorMessage'
import DocumentTemplatesTable from '../components/DocumentTemplatesTable.vue'

export default {
  name: 'Templates',

  components: {
    ErrorMessage,
    DocumentTemplatesTable
  }
}
</script>
