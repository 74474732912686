'use strict'

import axios from 'axios'

const { VUE_APP_IDP_URL } = process.env

export default async ({ email, password }) => {
  const response = await axios({
    method: 'post',
    url: `${VUE_APP_IDP_URL}/login`,
    headers: { 'Content-Type': 'application/json' },
    data: JSON.stringify({ email, password }),
    timeout: 8000
  })

  return response.data.jwt
}
