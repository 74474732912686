'use strict'

export default [
  { isoCode: 'en-GB', name: 'English - United Kingdom' },
  { isoCode: 'en-US', name: 'English - United States' },
  { isoCode: 'fr-FR', name: 'French - France' },
  { isoCode: 'sv-SE', name: 'Swedish - Sweden' },
  { isoCode: 'de-AT', name: 'German - Austria' },
  { isoCode: 'de-DE', name: 'German - Germany' },
  { isoCode: 'de-CH', name: 'German - Switzerland' },
  { isoCode: 'nb-NO', name: 'Norwegian (Bokmål) - Norway' },
  { isoCode: 'af-ZA', name: 'Afrikaans - South Africa' },
  { isoCode: 'sq-AL', name: 'Albanian - Albania' },
  { isoCode: 'ar-DZ', name: 'Arabic - Algeria' },
  { isoCode: 'ar-BH', name: 'Arabic - Bahrain' },
  { isoCode: 'ar-EG', name: 'Arabic - Egypt' },
  { isoCode: 'ar-IQ', name: 'Arabic - Iraq' },
  { isoCode: 'ar-JO', name: 'Arabic - Jordan' },
  { isoCode: 'ar-KW', name: 'Arabic - Kuwait' },
  { isoCode: 'ar-LB', name: 'Arabic - Lebanon' },
  { isoCode: 'ar-LY', name: 'Arabic - Libya' },
  { isoCode: 'ar-MA', name: 'Arabic - Morocco' },
  { isoCode: 'ar-OM', name: 'Arabic - Oman' },
  { isoCode: 'ar-QA', name: 'Arabic - Qatar' },
  { isoCode: 'ar-SA', name: 'Arabic - Saudi Arabia' },
  { isoCode: 'ar-SY', name: 'Arabic - Syria' },
  { isoCode: 'ar-TN', name: 'Arabic - Tunisia' },
  { isoCode: 'ar-AE', name: 'Arabic - United Arab Emirates' },
  { isoCode: 'ar-YE', name: 'Arabic - Yemen' },
  { isoCode: 'hy-AM', name: 'Armenian - Armenia' },
  { isoCode: 'Cy-az-AZ', name: 'Azeri (Cyrillic) - Azerbaijan' },
  { isoCode: 'Lt-az-AZ', name: 'Azeri (Latin) - Azerbaijan' },
  { isoCode: 'eu-ES', name: 'Basque - Basque' },
  { isoCode: 'be-BY', name: 'Belarusian - Belarus' },
  { isoCode: 'bg-BG', name: 'Bulgarian - Bulgaria' },
  { isoCode: 'ca-ES', name: 'Catalan - Catalan' },
  { isoCode: 'zh-CN', name: 'Chinese - China' },
  { isoCode: 'zh-HK', name: 'Chinese - Hong Kong SAR' },
  { isoCode: 'zh-MO', name: 'Chinese - Macau SAR' },
  { isoCode: 'zh-SG', name: 'Chinese - Singapore' },
  { isoCode: 'zh-TW', name: 'Chinese - Taiwan' },
  { isoCode: 'zh-CHS', name: 'Chinese (Simplified)' },
  { isoCode: 'zh-CHT', name: 'Chinese (Traditional)' },
  { isoCode: 'hr-HR', name: 'Croatian - Croatia' },
  { isoCode: 'cs-CZ', name: 'Czech - Czech Republic' },
  { isoCode: 'da-DK', name: 'Danish - Denmark' },
  { isoCode: 'div-MV', name: 'Dhivehi - Maldives' },
  { isoCode: 'nl-BE', name: 'Dutch - Belgium' },
  { isoCode: 'nl-NL', name: 'Dutch - The Netherlands' },
  { isoCode: 'en-AU', name: 'English - Australia' },
  { isoCode: 'en-BZ', name: 'English - Belize' },
  { isoCode: 'en-CA', name: 'English - Canada' },
  { isoCode: 'en-CB', name: 'English - Caribbean' },
  { isoCode: 'en-IE', name: 'English - Ireland' },
  { isoCode: 'en-JM', name: 'English - Jamaica' },
  { isoCode: 'en-NZ', name: 'English - New Zealand' },
  { isoCode: 'en-PH', name: 'English - Philippines' },
  { isoCode: 'en-ZA', name: 'English - South Africa' },
  { isoCode: 'en-TT', name: 'English - Trinidad and Tobago' },
  { isoCode: 'en-ZW', name: 'English - Zimbabwe' },
  { isoCode: 'et-EE', name: 'Estonian - Estonia' },
  { isoCode: 'fo-FO', name: 'Faroese - Faroe Islands' },
  { isoCode: 'fa-IR', name: 'Farsi - Iran' },
  { isoCode: 'fi-FI', name: 'Finnish - Finland' },
  { isoCode: 'fr-BE', name: 'French - Belgium' },
  { isoCode: 'fr-CA', name: 'French - Canada' },
  { isoCode: 'fr-LU', name: 'French - Luxembourg' },
  { isoCode: 'fr-MC', name: 'French - Monaco' },
  { isoCode: 'fr-CH', name: 'French - Switzerland' },
  { isoCode: 'gl-ES', name: 'Galician - Galician' },
  { isoCode: 'ka-GE', name: 'Georgian - Georgia' },
  { isoCode: 'de-AT', name: 'German - Austria' },
  { isoCode: 'de-DE', name: 'German - Germany' },
  { isoCode: 'de-LI', name: 'German - Liechtenstein' },
  { isoCode: 'de-LU', name: 'German - Luxembourg' },
  { isoCode: 'de-CH', name: 'German - Switzerland' },
  { isoCode: 'el-GR', name: 'Greek - Greece' },
  { isoCode: 'gu-IN', name: 'Gujarati - India' },
  { isoCode: 'he-IL', name: 'Hebrew - Israel' },
  { isoCode: 'hi-IN', name: 'Hindi - India' },
  { isoCode: 'hu-HU', name: 'Hungarian - Hungary' },
  { isoCode: 'is-IS', name: 'Icelandic - Iceland' },
  { isoCode: 'id-ID', name: 'Indonesian - Indonesia' },
  { isoCode: 'it-IT', name: 'Italian - Italy' },
  { isoCode: 'it-CH', name: 'Italian - Switzerland' },
  { isoCode: 'ja-JP', name: 'Japanese - Japan' },
  { isoCode: 'kn-IN', name: 'Kannada - India' },
  { isoCode: 'kk-KZ', name: 'Kazakh - Kazakhstan' },
  { isoCode: 'kok-IN', name: 'Konkani - India' },
  { isoCode: 'ko-KR', name: 'Korean - Korea' },
  { isoCode: 'ky-KZ', name: 'Kyrgyz - Kazakhstan' },
  { isoCode: 'lv-LV', name: 'Latvian - Latvia' },
  { isoCode: 'lt-LT', name: 'Lithuanian - Lithuania' },
  { isoCode: 'mk-MK', name: 'Macedonian (FYROM)' },
  { isoCode: 'ms-BN', name: 'Malay - Brunei' },
  { isoCode: 'ms-MY', name: 'Malay - Malaysia' },
  { isoCode: 'mr-IN', name: 'Marathi - India' },
  { isoCode: 'mn-MN', name: 'Mongolian - Mongolia' },
  { isoCode: 'nb-NO', name: 'Norwegian (Bokmål) - Norway' },
  { isoCode: 'nn-NO', name: 'Norwegian (Nynorsk) - Norway' },
  { isoCode: 'pl-PL', name: 'Polish - Poland' },
  { isoCode: 'pt-BR', name: 'Portuguese - Brazil' },
  { isoCode: 'pt-PT', name: 'Portuguese - Portugal' },
  { isoCode: 'pa-IN', name: 'Punjabi - India' },
  { isoCode: 'ro-RO', name: 'Romanian - Romania' },
  { isoCode: 'ru-RU', name: 'Russian - Russia' },
  { isoCode: 'sa-IN', name: 'Sanskrit - India' },
  { isoCode: 'Cy-sr-SP', name: 'Serbian (Cyrillic) - Serbia' },
  { isoCode: 'Lt-sr-SP', name: 'Serbian (Latin) - Serbia' },
  { isoCode: 'sk-SK', name: 'Slovak - Slovakia' },
  { isoCode: 'sl-SI', name: 'Slovenian - Slovenia' },
  { isoCode: 'es-AR', name: 'Spanish - Argentina' },
  { isoCode: 'es-BO', name: 'Spanish - Bolivia' },
  { isoCode: 'es-CL', name: 'Spanish - Chile' },
  { isoCode: 'es-CO', name: 'Spanish - Colombia' },
  { isoCode: 'es-CR', name: 'Spanish - Costa Rica' },
  { isoCode: 'es-DO', name: 'Spanish - Dominican Republic' },
  { isoCode: 'es-EC', name: 'Spanish - Ecuador' },
  { isoCode: 'es-SV', name: 'Spanish - El Salvador' },
  { isoCode: 'es-GT', name: 'Spanish - Guatemala' },
  { isoCode: 'es-HN', name: 'Spanish - Honduras' },
  { isoCode: 'es-MX', name: 'Spanish - Mexico' },
  { isoCode: 'es-NI', name: 'Spanish - Nicaragua' },
  { isoCode: 'es-PA', name: 'Spanish - Panama' },
  { isoCode: 'es-PY', name: 'Spanish - Paraguay' },
  { isoCode: 'es-PE', name: 'Spanish - Peru' },
  { isoCode: 'es-PR', name: 'Spanish - Puerto Rico' },
  { isoCode: 'es-ES', name: 'Spanish - Spain' },
  { isoCode: 'es-UY', name: 'Spanish - Uruguay' },
  { isoCode: 'es-VE', name: 'Spanish - Venezuela' },
  { isoCode: 'sw-KE', name: 'Swahili - Kenya' },
  { isoCode: 'sv-FI', name: 'Swedish - Finland' },
  { isoCode: 'sv-SE', name: 'Swedish - Sweden' },
  { isoCode: 'syr-SY', name: 'Syriac - Syria' },
  { isoCode: 'ta-IN', name: 'Tamil - India' },
  { isoCode: 'tt-RU', name: 'Tatar - Russia' },
  { isoCode: 'te-IN', name: 'Telugu - India' },
  { isoCode: 'th-TH', name: 'Thai - Thailand' },
  { isoCode: 'tr-TR', name: 'Turkish - Turkey' },
  { isoCode: 'uk-UA', name: 'Ukrainian - Ukraine' },
  { isoCode: 'ur-PK', name: 'Urdu - Pakistan' },
  { isoCode: 'Cy-uz-UZ', name: 'Uzbek (Cyrillic) - Uzbekistan' },
  { isoCode: 'Lt-uz-UZ', name: 'Uzbek (Latin) - Uzbekistan' },
  { isoCode: 'vi-VN', name: 'Vietnamese - Vietnam' }
]
