var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"light":"","max-width":"600"},model:{value:(_vm.showCreateDocumentTemplateDialog),callback:function ($$v) {_vm.showCreateDocumentTemplateDialog=$$v},expression:"showCreateDocumentTemplateDialog"}},[(_vm.showCreateDocumentTemplateDialog)?_c('v-card',{attrs:{"data-test":"create-dialog"}},[_c('v-toolbar',{attrs:{"flat":"","dark":""}},[_c('v-toolbar-title',{attrs:{"data-test":"create-dialog-title"}},[_vm._v(" Create New Document Template ")]),_c('div',{staticClass:"flex-grow-1"}),_c('v-toolbar-title',[_c('v-btn',{attrs:{"small":"","fab":"","depressed":""},on:{"click":function($event){$event.preventDefault();_vm.showCreateDocumentTemplateDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',{staticClass:"ma-0 pa-10"},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.createNewTemplate)}}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"error-messages":errors,"success":valid,"items":_vm.documentTemplateTypeSelectItems,"label":"Document Type","required":""},model:{value:(_vm.createTemplate.documentType),callback:function ($$v) {_vm.$set(_vm.createTemplate, "documentType", $$v)},expression:"createTemplate.documentType"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"error-messages":errors,"success":valid,"items":_vm.isoCodeSelectItems,"label":"Locale","required":""},model:{value:(_vm.createTemplate.locale),callback:function ($$v) {_vm.$set(_vm.createTemplate, "locale", $$v)},expression:"createTemplate.locale"}})]}}],null,true)}),(_vm.createTemplate.documentType)?[_c('v-select',{staticClass:"mt-10",attrs:{"items":_vm.cloneVersionSelectItems,"label":"Create New Template From Existing Document Template Version?","clearable":true,"required":""},model:{value:(_vm.createTemplate.cloneId),callback:function ($$v) {_vm.$set(_vm.createTemplate, "cloneId", $$v)},expression:"createTemplate.cloneId"}})]:_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"type":"submit","block":"","data-test":"create-new-dialog-button","disabled":invalid}},on),[_vm._v(" Create new document template "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-plus-circle ")])],1)]}}],null,true)},[_c('span',[_vm._v("Create New Document Template")])])],2)]}}],null,false,662835327)})],1)],1):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"340"},on:{"click:outside":function($event){return _vm.deleteDocumentTemplateDialog()}},model:{value:(_vm.showDeleteDocumentTemplateDialog),callback:function ($$v) {_vm.showDeleteDocumentTemplateDialog=$$v},expression:"showDeleteDocumentTemplateDialog"}},[(_vm.showDeleteDocumentTemplateDialog)?_c('v-card',{attrs:{"data-test":"delete-document-template-dialog","light":""}},[_c('v-card-title',{staticClass:"pa-0"},[_c('v-app-bar',{attrs:{"light":"","flat":""}},[_vm._v(" Delete Unpublished Template ")])],1),_c('v-card-text',{staticClass:"pa-4"},[_vm._v(" Are you sure you wish to delete this template? ")]),_c('v-card-actions',{staticClass:"pa-4 pt-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","data-test":"delete-document-template-dialog-cancel-button"},on:{"click":function($event){return _vm.deleteDocumentTemplateDialog()}}},[_vm._v(" No ")]),_c('v-btn',{attrs:{"dark":"","data-test":"delete-document-template-dialog-confirm-button"},on:{"click":function($event){return _vm.deleteDocumentTemplate(_vm.deleteDocumentTemplateId)}}},[_vm._v(" Yes ")])],1)],1):_vm._e()],1),_c('v-card',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" Document Templates ")]),_c('div',{staticClass:"flex-grow-1"}),_c('v-toolbar-title',[_c('v-row',[_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","data-test":"create-new-button"},on:{"click":function($event){$event.stopPropagation();return _vm.openCloseCreateDialog()}}},on),[_vm._v(" Create new "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-plus-circle ")])],1)]}}])},[_c('span',[_vm._v("Create New Template")])])],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"px-3 py-0"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","hide-details":"","filled":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.rows,"items-per-page":20,"sort-by":['documentType', 'localeText', 'version'],"sort-desc":[false, false, true],"multi-sort":"","footer-props":_vm.footerProps,"fixed-header":true,"search":_vm.search,"item-key":"id","data-test":"table"},scopedSlots:_vm._u([{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [(item.isActive)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"data-test":"active-item-icon"}},on),[_vm._v(" mdi-eye ")])]}}],null,true)},[_c('span',[_vm._v("Template Active")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"data-test":"not-active-item-icon"}},on),[_vm._v(" mdi-eye-off ")])]}}],null,true)},[_c('span',[_vm._v("Template Not Active")])])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"nowrap"},[(!_vm.isDevelopmentEnvironment && item.isPublished)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","small":"","icon":"","data-test":"view-item-button"},on:{"click":function($event){$event.stopPropagation();return _vm.navigateToEditor(item.id)}}},on),[_c('v-icon',[_vm._v(" mdi-file-document ")])],1)]}}],null,true)},[_c('span',[_vm._v("View Template")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","small":"","icon":"","data-test":"edit-item-button"},on:{"click":function($event){$event.stopPropagation();return _vm.navigateToEditor(item.id)}}},on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Template")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","small":"","icon":"","data-test":"clone-item-button"},on:{"click":function($event){$event.stopPropagation();return _vm.openCloseCreateDialog({
                  cloneId: item.id,
                  documentType: item.documentType,
                  locale: item.locale
                })}}},on),[_c('v-icon',[_vm._v(" mdi-content-copy ")])],1)]}}],null,true)},[_c('span',[_vm._v("Clone Template")])]),(item.isPublished)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mx-2",attrs:{"data-test":"block-item-icon"}},on),[_vm._v(" mdi-block-helper ")])]}}],null,true)},[_c('span',[_vm._v("No Delete Available")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","small":"","icon":"","data-test":"delete-item-button"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteDocumentTemplateDialog(item.id)}}},on),[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete Template")])])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }