<template>
  <v-app-bar
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    style="padding-left: 10px;"
    app
    fixed
  >
    <v-toolbar-title>
      <v-btn
        to="/document-templates"
      >
        Meavitae Templates
      </v-btn>
    </v-toolbar-title>

    <v-spacer />

    <v-btn
      @click="logout"
    >
      Logout
    </v-btn>
  </v-app-bar>
</template>

<script>

export default {
  name: 'AppBar',

  methods: {
    logout () {
      sessionStorage.removeItem('jwt')
      this.$store.commit('unsetUser')
    }
  }
}
</script>
