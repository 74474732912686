import documentTemplates from './documentTemplates'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    errorCode: null,
    userId: null,
    userRole: null
  },

  modules: {
    documentTemplates
  },

  getters: {
    isLoggedIn: state => !!state.userId
  },

  actions: {},

  mutations: {
    setStateValue: (state, { key, value }) => {
      Vue.set(state, key, value)
    },

    setUser: (state, { userId, userRole }) => {
      Vue.set(state, 'userId', userId)
      Vue.set(state, 'userRole', userRole)
    },

    unsetUser: state => {
      Vue.set(state, 'userId', null)
      Vue.set(state, 'userRole', null)
    }
  }
})
