'use strict'

// import { createId } from '../utils'

const getIndexInState = objects => id => objects
  ? objects.findIndex(objInState => objInState.id === id)
  : -1

export default {
  getters: {
    getObject: state => objectId => state.find(({ id }) => id === objectId),
    getObjects: state => state
  },

  mutations: {
    updateState (state, object) {
      // if (!object.id) object.id = createId()

      const objIndexInState = getIndexInState(state)(object.id)

      objIndexInState > -1
        ? state.splice(objIndexInState, 1, object)
        : state.push(object)
    },

    removeObjectFromState (state, id) {
      const objIndexInState = getIndexInState(state)(id)
      state.splice(objIndexInState, 1)
    }
  }
}
