'use strict'

import jwtDecode from 'jwt-decode'

const documentTemplateTypeLookup = {
  will: 'Will Template',
  livingWill: 'Living Will',
  childCare: 'Child Care',
  medicalConsentChildren: 'Medical Consent Children',
  travelConsentChildren: 'Travel Consent Children',
  questionnaire7iM: '7iM Questionnaire',
  unconstrainedFund7iM: '7iM Unconstrained Fund',
  ukHousingActSectionTwentyOneAstonVeer: 'Aston Veer - UK Housing Act Section Twenty One',
  ukHousingActSectionEightAstonVeer: 'Aston Veer - UK Housing Act Section Eight',
  ukLandRegistryTransferAstonVeer: 'Aston Veer - UK Land Registry Transfer Of Registered Title',
  nonDisclosureAgreementAstonVeer: 'Aston Veer - Non-Disclosure Agreement',
  propertySaleAgreementOneAstonVeer: 'Aston Veer - Property Sale Agreement',
  propertyDeclarationOfTrustAstonVeer: 'Aston Veer - Property Declaration Of Trust',
  deedOfRevocationForPowerOfAttorneyAstonVeer: 'Aston Veer - Deed Of Revocation For A Power Of Attorney',
  generalReleaseOfLiabilityAstonVeer: 'Aston Veer - General Release Of Liability',
  debtLetterBeforeLegalActionAstonVeer: 'Aston Veer - Letter To Debtor Before Legal Action',
  loanAgreementAstonVeer: 'Aston Veer - Loan Agreement',
  contractTerminationAgreementAstonVeer: 'Aston Veer - Contract Termination Agreement',
  courtOfProtectionAstonVeer: 'Aston Veer - Court of Protection',
  lpaHealthAndWelfareAstonVeer: 'Aston Veer - LPA health and welfare',
  lpaPropertyAndFinanceAstonVeer: 'Aston Veer - LPA property and finance'
}

const getUserDetailsFromJwt = jwt => {
  const { exp, userId, permissions = [] } = jwtDecode(jwt)

  if (!userId) throw new Error('UserId Does Not Exist In Jwt')
  if (!permissions) throw new Error('User Permissions Does Not Exist In Jwt')

  const nowDate = Math.floor(Date.now() / 1000) + (60 * 60)
  if (nowDate > exp) throw new Error('User Session Has Expired')

  const userRole = permissions
    .filter(({ scope }) => scope === 'template')
    .map(({ scope, role }) => role)
    .pop()
  if (!userRole) throw new Error('User Does Not Have Permission')

  return { userId, userRole }
}

const encodeTemplateString = template => btoa(unescape(encodeURIComponent(template)))
const decodeTemplateString = encodedTemplate => decodeURIComponent(escape(atob(encodedTemplate)))

export {
  decodeTemplateString,
  documentTemplateTypeLookup,
  encodeTemplateString,
  getUserDetailsFromJwt
}
