export default `
<p>
  Templates can be built in a combination of <a href="https://daringfireball.net/projects/markdown/syntax#block" target=_blank">Markdown</a>
  and <a href="https://handlebarsjs.com/guide/builtin-helpers.html" target="_blank">Handlebars</a> expressions.
</p>
<h1 class="my-8">
  Markdown
</h1>
<p>
  Markdown is a way to style text. You control the display of the document; formatting words as bold or italic, adding images,
  and creating lists are just a few of the things we can do with Markdown. Mostly, Markdown is just regular text with a
  few non-alphabetic characters thrown in, like # or *.
</p>
<h2 class="my-8">
  Headers
</h2>
<pre class="my-5">
# This is a Header1

## This is a Header2

###### This is an Header6
</pre>
<h2 class="my-8">
  Lists
</h2>
<p>
  Lists can be unordered and ordered:
</p>
<pre class="my-5">
* Red
* Green
* Blue

1. Bird
2. McHale
3. Parish
</pre>
<h2 class="my-8">
  Emphasis
</h2>
<pre class="my-5">
Italics: *single asterisks* 

Bold: **double asterisks**
</pre>
<h2 class="my-8">
  Blockquotes
</h2>
<pre class="my-5">
> This is a blockquote with two paragraphs. Lorem ipsum dolor sit amet,
> consectetuer adipiscing elit. Aliquam hendrerit mi posuere lectus.
> Vestibulum enim wisi, viverra nec, fringilla in, laoreet vitae, risus.
> 
> Donec sit amet nisl. Aliquam semper ipsum sit amet velit. Suspendisse
> id sem consectetuer libero luctus adipiscing.
</pre>
<pre class="my-5">
* * *
</pre>
<h2 class="my-8">
  Horizontal rule
</h2>
<h1 class="my-8">
  Handlebars
</h1>
<p>
  Handlebars is a simple templating language. It uses a template and an input object to generate rendered output.
</p>
<p>
  A handlebar expression is wrapped in double curly braces (mustaches) {{propertyName}} When the
  template is executed, these expressions are replaced with values from an input object.
</p>
<p>
  Handlebars expressions can be used to implement logic in the rendered output:
</p>
<h2 class="my-8">
  Variable
</h2>
<p>
  A valiable holds a value, this can be a string of text, a boolean (true or false) an object ( house: { address: 'example address' } )
  or an array ( ageList: [12, 56, 99] ).
</p>
<p>
  You can access the value held in a variable by for example {{ variableName }}. To access a variable inside
  an object you can use the . (dot) to access children, e.g. {{ funeralType.text }}
</p>
<h2 class="my-8">
  Headings
</h2>
<p>
  Three different expressions can be used throughout to create an incrementing three-level numbering system for headings. For example:
</p>
<pre class="my-5">
{{majorNum}} This is major title 1

{{majorNum}} This is major title 2

{{minorNum}} This is minor title 2.1

{{subMinorNum}} This is sub minor title 2.1.1

{{subMinorNum}} This is sub minor title 2.1.2

{{minorNum}} This is minor title 2.2

{{subMinorNum}} This is sub minor title 2.2.1

{{majorNum}} This is major title 3

{{titleNum}} This will output the current title number 3 (without incrementing it)
</pre>
<h2 class="my-8">
  If (variable truthy), and if else
</h2>
<p>
  An if statement can be used to conditionally render a block of text / Handlebar expressions, when the value of the variable
  being tested is truthy (has a value, is not null or is not undefined). For example if you wanted to output a
  block of text if the person making the will had a spouse, but if not an alternative block of text:
</p>
<pre class="my-5">
{{#if spouse}}
The subject of this will {{ user.firstName }} {{ user.lastName }} has a spouse.
{{else}}
The subject of this will {{ user.firstName }} {{ user.lastName }} is single.
{{/if}}
</pre>
<h2 class="my-8">
  Unless (variable falsy)
</h2>
<p>
  Unless is the opposite to if. If will render a block of text/expressions when the variable is truthy, Unless will render
  a block of text/expressions when the variable is falsy (null, undefined value, empty string):
</p>
<pre class="my-5">
{{#unless spouse}}
{{ user.firstName }} {{ user.lastName }} is not married.
{{/unless}}
</pre>
<h2 class="my-8">
  Compare
</h2>
<p>
  If only has the ability to render a block of text/expressions when the variable is truthy. Compare can be used to
  render a block when the variable either equals/not equals/is greater than/is less than a certain value. For example:
</p>
<pre class="my-5">
{{#compare residuaryEstatePayableInheritanceTaxInstruction "paidByTheEstate"}}
The inheritance tax for the residuary estate will be paid by the estate.
{{/compare}}
</pre>
<p>
  The above will look at the value held by the variable residuaryEstatePayableInheritanceTaxInstruction, and see if it
  is exactly equal to the string of text "paidByTheEstate".
</p>
<p>
  Compare can also be used with specific operators, to make a comparison:
  <br /><br />
  "===" Exactly equal to: {{#compare variableName "===" "string of text"}}<br />
  "!==" Exactly not equal to: {{#compare variableName "!==" anotherVariableName}}<br />
  ">" Greater than: {{#compare variableName ">" 5}}<br />
  "<" Less than: {{#compare variableName "<" anotherVariableName}}<br />
  ">=" Greater than or equal to: {{#compare variableName ">=" 2}}<br />
  "<=" Less than or equal to: {{#compare variableName "<=" anotherVariableName}}<br />
  "&&" And. Both variables are truthy: {{#compare variableName "&&" anotherVariableName}}<br />
  "||" Or. At least one of the two variables is truthy: {{#compare variableName "||" anotherVariableName}}<br />
  <br /><br />
  If no operator is given, then it defaults to exactly equals "===" {{#compare variableName "string of text"}}
</p>
<p>
  You can also use else inside a compare, to output the opposite:
</p>
<pre class="my-5">
{{#compare residuaryEstatePayableInheritanceTaxInstruction "===" "paidByTheEstate"}}
The inheritance tax for the residuary estate will be paid by the estate.
{{else}}
The inheritance tax for the residuary estate will NOT be paid by the estate.
{{/compare}}
</pre>
<h2 class="my-8">
  Arrays and looping through them
</h2>
<p>
  Arrays in the will input object can be seen wrapped in square brackets [] Arrays can hold multiple values
  (seperated by , commas), for example and array of numbers would look like: variableName: [1, 56, 456]
  An array of strings (text) would look like: variableName: ['string 1', 'string 2', 'string 3'] But the most
  commonly used is an array of objects:
</p>
<pre class="my-5">
executors: [
  {
    firstName: 'Jim',
    lastName: 'McDonald'
    addresses: [
      {
        value: '15 Coranation Street',
        isPref: false,
      }
    ]
  },
  {
    firstName: 'Liz',
    lastName: 'McDonald'
    addresses: [
      {
        value: '15 Coranation Street',
        isPref: true,
      }
    ]
  },
]
</pre>
<p>The first method, you can directly access an array's values by their
  index's inside the array. E.g. an array of three strings fullName: ["Jim", "Frank Paul", "McDonald"] could be
  accessed by usings each element of the arrays index. The first element inside an array has the index value 0,
  the second element inside an array has the index value 1, the third element will have the index value 2.
  To output the full name of this example array would be:<br /><br />
  The full name of this person is {{ fullName.[0] fullName.[1] fullName.[2] }}
</p>
<p>
  More complex arrays such as the array of objects executor example above, could also be directly accessed:
  <br /><br />
  The full name of the first executor is: {{ executors.[0].firstName }} {{ executors.[0].lastName }}
  <br />
  The address of the first executor is: {{executors.[0].addresses.[0].value }}
</p>
<p>
  However in most instances it would be impractical to use this method as the size of the array may not be known,
  for instance there may only be one executor inside the executor array, so {{ executors.[1].firstName }} would output
  nothing due to their not being anything inside index 1 (the second element in the array).
</p>
<p>
  The second method, and recommended is to loop through the array. You can do this using the Handlebars helper
  each:
</p>
<pre class="my-5">
The executors of this will are:
{{#each executors}}
Executor Name: {{firstName}} {{lastName}} 
{{/each}}
</pre>
<p>
  When using each to work inside the array you will be able to directly access an objects properties, as shown in the exaple above
  with {{ firstName }} and {{ lastName }} instead of {{ executors.[0].firstName }} {{ executors.[0].lastName }}
</p>
<p>
  The length of an array (number of items inside the array) can be determined by attaching the dot length property onto an array: {{executors.length}}
</p>
<p>
  However, when inside an array loop ../ must be used before the array variable name, to move back up one level to access the array's length property: {{#each guardians}}There is {{../guardians.length}} guardian{{/each}}
</p>
<p>
  All of the above Handlebar expressions can be used incombinations to form more complex logic in your templates, for example to
  only render executors with the first name Frank:
</p>
<pre class="my-5">
The executors of this will are:
{{#each executors}}
There {{#compare ../executors.length "<" 2}}is 1 executor{{else}}are {{../executors.length}} executors.{{/compare}}
{{#compare firstName "===" "Frank"}}
Executor Name: {{firstName}} {{lastName}}
{{/compare}}
{{/each}}
</pre>
<h2 class="my-8">
  Sub-Expressions
</h2>
<p>
  Arrays of objects can be filtered using the sub-expression helper called 'filter'.
  Sub-expressions are passed into hadlebars expressions in place of a variable.
  The filter sub-expression takes 4 arguments besides it's name: (filter arrayName <i>variable-inside-object-to-check</i> <i>operator</i> <i>value-to-check-against</i>).
  For example you may only want to output executors with the first name frank, you could build the sub-expression:
  {{#each (filter executors "firstName" "===" "frank")}}This is Frank {{lastName}}{{/each}}
</p>
<p>

</p>
<pre class="my-5">
{{#if (filter monetaryDebts "isDebtorToBeReleasedFromDebt" "===" true)}}
Contacts to be released from their debts:

{{#each (filter monetaryDebts "isDebtorToBeReleasedFromDebt" "===" true)}}
Name: {{debtor.firstName}} {{debtor.lastName}}
{{/each}}
{{/if}}
</pre>
<h2 class="my-8">
  Contact
</h2>
<p>
  The contact block helper makes it easier and more concise to refer to properties of the contact object in the templates.
  For example to out put the full name of the first guardian contact you would have to output:
  {{guardians.[0].firstName}}{{#if guardians.[0].middleNames}} {{guardians.[0].middleNames}}{{/if}} {{guardians.[0].lastName}}.
  The contact block helper exposes the properties of that contact by passing the contact variable into it:
</p>
<pre class="my-5">
{{#contact guardians.[0]}}
First Name: {{firstName}}
Middle Names: {{middleNames}}
Last Name: {{lastName}}

Full Name (First, middle if it exists and last name): {{fullName}}

Preferred address or 1st address: {{address}}

Preferred email address or 1st email address: {{emailAddress}}

Preferred phone number or first phone number if no preferred: {{phoneNumber}}

Relationship to the user (e.g. husband, grandson, niece etc): {{relationshipToUser}}

Personal Pronoun Subject (e.g. he, she): {{personalPronounSubject}}

Personal Pronoun Object (e.g. him, her): {{personalPronounObject}}

Possesive Pronoun (e.g. his, her): {{possessivePronoun}}
{{/contact}}
</pre>
<h2 class="my-8">
  Group String Name (group beneficiary)
</h2>
<p>
  You can use the {{groupStringName group}} helper to produce relevant beneficiary text depending upon the group.name property:<br /><br />
  group.name is 'spouse' - Outputs: my spouse if they are alive at my death<br />
  group.name is 'civilPartner' - Outputs: my civil partner if they are alive at my death<br />
  group.name is 'guardians' - Outputs: the guardians of my children under the age of 18 who are alive at the time of my death in equal share<br />
  group.name is 'substituteGuardians' - Outputs: the substitute guardians of my children under the age of 18 who are alive at the time of my death in equal share<br />
  group.name is 'substituteExecutors' - Outputs: my substitute executors who are alive at the time of my death in equal share<br />
  group.name is any other string - Outputs: my (any other group name here) who are alive at the time of my death in equal share<br />
</p>
<h2 class="my-8">
  Other helpers
</h2>
<p>
  You can add an inline comment/note/reminder in a template. This will not appear in the rendered document: {{#comment}}This text will not appear in the rendered document{{/comment}}
</p>
<p>
  The following text formatting helpers can be used:
</p>
<pre class="my-5">
{{#uppercase}}Transform this text{{/uppercase}} - Becomes: TRANSFORM THIS TEXT
{{#lowercase}}TransFORM tHIS texT{{/lowercase}} - Becomes: transform this text
{{#titlecase}}transform this text{{/titlecase}} - Becomes: Transform This Text
{{#uppercasefirst}}transform tHIS texT{{/uppercasefirst}} - Becomes: Transform tHIS texT
{{#underline}}Transform THIS text{{/underline}} - Becomes: <u>Transform THIS text</u>
{{formatMoney numberVariable "en-GB"}} - Becomes: &pound;1,234.56 or {{formatMoney 1234.56 "en-US"}} - Becomes: &dollar;1,234.56
{{numToMoneyWords numberVariable "en-GB"}} - Becomes: One thousand two hundred thirty four pounds point five six pence
{{numToWords 1234.56 "en-US"}} - Becomes: One thousand two hundred thirty four point five six 
{{{pageBreak}}} - Insert a page break in the document the tempate produces (Note: the use of the triple handlebar mustaches)
</pre>
`
