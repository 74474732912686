'use strict'

import axios from 'axios'
import createEndPoint from '../utils/createEndPoint'

const { VUE_APP_TMS_URL } = process.env

export default async ({ jwt, type, locale, version }) => {
  if (!jwt) throw new Error('jwt not provided')

  const endPoint = createEndPoint({ type, locale, version })

  return axios({
    method: 'get',
    headers: { Authorization: jwt },
    url: `${VUE_APP_TMS_URL}/${endPoint}`,
    timeout: 8000
  })
}
