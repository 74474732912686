<template>
  <div>
    <v-dialog v-model="showDataObject" max-width="70rem">
      <v-card color="#303030">
        <v-card-title>
          Data Object
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="selectedDataObjectIndex"
            :items="dataObjectItems"
          />
          <pre>{{ dataObject }}</pre>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showTemplateExamples" max-width="70rem">
      <v-card color="#303030">
        <v-card-title>Template Examples</v-card-title>
        <v-card-text v-html="templateExamples" />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showPublishActivateDialog"
      max-width="400"
      @click:outside="openCloseDialog('showPublishActivateDialog')"
    >
      <v-card
        v-if="showPublishActivateDialog"
        light
      >
        <v-card-title class="pa-0">
          <v-app-bar
            light
            flat
          >
            {{ !editableDocumentTemplate.isPublished ? 'Publish Template' : !editableDocumentTemplate.isActive ? 'Activate Template' : 'Deactivate Template' }}

            <div class="flex-grow-1" />

            <v-toolbar-title>
              <v-btn
                small
                fab
                depressed
                @click.prevent="openCloseDialog('showPublishActivateDialog')"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-title>
          </v-app-bar>
        </v-card-title>

        <v-card-text class="pa-4">
          <template v-if="!editableDocumentTemplate.isPublished">
            <p>Published: After publish, the template cannot be edited or deleted.</p>
            <p>Active: A published active template will be listed on the Meavitae app for users to select.</p>
          </template>

          <template v-else>
            <p>Active: A published active template will be listed on the Meavitae app for users to select.</p>
          </template>
        </v-card-text>

        <v-card-actions class="pa-4 pt-0">
          <v-btn
            v-if="!editableDocumentTemplate.isPublished"
            dark
            @click.stop="submit({
              ...editableDocumentTemplate,
              isPublished: true,
              isActive: false
            })"
          >
            Publish Only
          </v-btn>

          <v-spacer />

          <v-btn
            dark
            @click.stop="submit({
              ...editableDocumentTemplate,
              isPublished: true,
              isActive: editableDocumentTemplate.isActive ? false : true
            })"
          >
            {{ `${ !editableDocumentTemplate.isPublished ? 'Publish and ' : '' }${editableDocumentTemplate.isActive ? 'Deactivate' : 'Activate'}` }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card class="mb-6">
      <v-speed-dial
        v-model="helpButton"
        fixed
        :bottom="true"
        :right="true"
        direction="top"
      >
        <template v-slot:activator>
          <v-btn
            v-model="helpButton"
            fab
            :color="validTemplateContent ? 'green' : 'red'"
          >
            <v-icon v-if="helpButton">mdi-close</v-icon>
            <v-icon v-else>mdi-help</v-icon>
          </v-btn>
        </template>

        <v-btn
          fab
          small
          @click="openCloseDialog('showTemplateExamples')"
        >
          <v-icon>mdi-information</v-icon>
        </v-btn>

        <v-btn
          fab
          small
          @click="openCloseDialog('showDataObject')"
        >
          <v-icon>mdi-code-braces</v-icon>
        </v-btn>
      </v-speed-dial>

      <div v-if="showErrorMessage">
        {{ errorMessage }}
      </div>

      <v-card-title class="py-0">
        <v-container class="pa-0">
          <v-row>
            <v-col cols="4" style="text-align: left;">
              <span>Document Type: {{ editableDocumentTemplate.documentType }}</span>
            </v-col>

            <v-col cols="4" style="text-align: center;">
              <span>Locale: {{ editableDocumentTemplate.locale }}</span>
            </v-col>

            <v-col cols="4" style="text-align: right;">
              <span>Version: {{ editableDocumentTemplate.version }}</span>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="4"
              class="pb-0 pt-3 text-left"
            >
              <span>Published: {{ editableDocumentTemplate.isPublished ? 'Yes' : 'No' }}</span>
            </v-col>

            <v-col cols="4" class="py-0 px-0">
              <v-select
                v-model="selectedDataObjectIndex"
                :items="dataObjectItems"
                class="py-0 px-0"
              />
            </v-col>

            <v-col
              cols="4"
              class="pb-0 pt-3 text-right"
            >
              <span>Active: {{ editableDocumentTemplate.isActive ? 'Yes' : 'No' }}</span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>

      <validation-observer v-slot="{ handleSubmit }">
        <v-form @submit.prevent="handleSubmit(submit(editableDocumentTemplate))">
          <v-card-text>
            <v-row dense>
              <v-col cols="12" lg="6">
                <v-card height="100%" color="#303030">
                  <v-card-title>
                    Editor

                    <v-icon
                      v-if="validTemplateContent"
                      right
                      color="green"
                    >
                      mdi-thumb-up
                    </v-icon>

                    <v-icon
                      v-else
                      right
                      color="red"
                      @click="showErrorMessage = !showErrorMessage"
                    >
                      mdi-thumb-down
                    </v-icon>
                  </v-card-title>
                  <v-card-text>
                    <validation-provider v-slot="{ errors, valid }" rules="required">
                      <v-textarea
                        v-model="editableDocumentTemplate.template"
                        :disabled="!isDevelopmentEnvironment && editableDocumentTemplate.isPublished"
                        :error-messages="errors"
                        :success="valid"
                        filled
                        label="Template"
                        height="60rem"
                      />
                    </validation-provider>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col>
                <v-card height="100%" color="#303030">
                  <v-card-title> Result</v-card-title>
                  <v-card-text>
                    <div
                      class="pa-3"
                      style="background-color: #FFFFFF; color: #000000; height: 60rem; overflow-y: auto;"
                      v-html="compiledMarkdown"
                    />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-row>
              <v-col cols="6">
                <v-btn @click.stop="openCloseDialog('showPublishActivateDialog')" :disabled="!validTemplateContent">
                  {{ !editableDocumentTemplate.isPublished ? 'Publish Template' : !editableDocumentTemplate.isActive ? 'Activate Template' : 'Deactivate Template' }}
                </v-btn>
              </v-col>

              <v-col cols="6" class="text-right">
                <v-btn
                  v-if="showSubmitButton"
                  :disabled="!validTemplateContent"
                  type="submit"
                >
                  Save Draft
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-form>
      </validation-observer>
    </v-card>
  </div>
</template>

<script>
import createDOMPurify from 'dompurify'
import marked from 'marked'
import mvHandlebars from '@meavitae/mv-handlebars'
import templateExamples from '../utils/templateExamples'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { debounce } from 'lodash'

const { VUE_APP_ENVIRONMENT } = process.env

const DOMPurify = createDOMPurify(window)

export default {
  name: 'TemplateEditor',

  components: {
    ValidationObserver,
    ValidationProvider
  },

  props: {
    documentTemplate: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      showPublishActivateDialog: false,
      showTemplateExamples: false,
      showDataObject: false,
      editableDocumentTemplate: this.documentTemplate,
      helpButton: false,
      templateExamples,
      selectedDataObjectIndex: 0,
      dataObjectOptions: [],
      compiledMarkdownContent: '',
      validTemplateContent: true,
      errorMessage: '',
      showErrorMessage: false
    }
  },

  computed: {
    isDevelopmentEnvironment () {
      return VUE_APP_ENVIRONMENT === 'development'
    },

    showSubmitButton () {
      return !(
        !this.isDevelopmentEnvironment &&
        this.editableDocumentTemplate.isPublished
      )
    },

    dataObject () {
      return (
        this.dataObjectOptions[this.selectedDataObjectIndex] &&
        this.dataObjectOptions[this.selectedDataObjectIndex].value
      ) || {}
    },

    dataObjectItems () {
      return this.dataObjectOptions
        .map(({ name }, index) => ({
          text: name,
          value: index
        }))
    },

    compiledMarkdown () {
      try {
        const source = this.editableDocumentTemplate.template
        const context = this.dataObject
        const template = mvHandlebars(source, context)

        this.compiledMarkdownContent = DOMPurify
          .sanitize(marked(template, { gfm: true }))

        this.validTemplateContent = true
        return this.compiledMarkdownContent
      } catch (error) {
        console.log(error)
        this.errorMessage = error.message
        this.validTemplateContent = false
        return this.compiledMarkdownContent
      }
    }
  },

  mounted () {
    import(`../json_files/${this.editableDocumentTemplate.documentType}.${this.editableDocumentTemplate.locale}.json`)
      .then(data => {
        this.dataObjectOptions = data.default
      })
      .catch(error => {
        throw error
      })
  },

  methods: {
    openCloseDialog (dialogStatusVariable) {
      this[dialogStatusVariable] = !this[dialogStatusVariable]
    },

    update: debounce(function (e) {
      this.editableTemplate = e.target.value
    }, 300),

    submit (saveDocumentTemplate = null) {
      this.$emit('submitted', saveDocumentTemplate)
    }
  }
}

</script>
