'use strict'

import md5 from 'md5'
import makeTmsPutRequest from '../requests/makeTmsPutRequest'

export default async ({ id, jwt, object }) => {
  const md5Hash = md5(object)
  const apiResponse = await makeTmsPutRequest({ id, jwt, object })
  const returnedMd5 = apiResponse.data

  if (md5Hash !== returnedMd5) throw new Error('returned md5 hash does not match sent md5')

  return true
}
