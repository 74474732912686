'use strict'

import makeTmsGetRequest from '../requests/makeTmsGetRequest'
import { decodeTemplateString } from '../../../utils'

export default async ({ type, jwt, locale, version }) => {
  const apiArray = await makeTmsGetRequest({ type, jwt, locale, version })
  return apiArray.data.map(({ key, value: { template, ...restOfTemplate } }) => ({
    id: key,
    ...restOfTemplate,
    template: decodeTemplateString(template)
  }))
}
