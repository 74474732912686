<template>
  <div>
    <v-dialog
      v-model="showCreateDocumentTemplateDialog"
      light
      max-width="600"
    >
      <v-card
        v-if="showCreateDocumentTemplateDialog"
        data-test="create-dialog"
      >
        <v-toolbar
          flat
          dark
        >
          <v-toolbar-title
            data-test="create-dialog-title"
          >
            Create New Document Template
          </v-toolbar-title>

          <div class="flex-grow-1" />

          <v-toolbar-title>
            <v-btn
              small
              fab
              depressed
              @click.prevent="showCreateDocumentTemplateDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-title>
        </v-toolbar>

        <v-card-text
          class="ma-0 pa-10"
        >
          <validation-observer v-slot="{ handleSubmit, invalid }">
            <v-form
              @submit.prevent="handleSubmit(createNewTemplate)"
            >
              <validation-provider v-slot="{ errors, valid }" rules="required">
                <v-select
                  v-model="createTemplate.documentType"
                  :error-messages="errors"
                  :success="valid"
                  :items="documentTemplateTypeSelectItems"
                  label="Document Type"
                  required
                />
              </validation-provider>

              <validation-provider v-slot="{ errors, valid }" rules="required">
                <v-select
                  v-model="createTemplate.locale"
                  :error-messages="errors"
                  :success="valid"
                  :items="isoCodeSelectItems"
                  label="Locale"
                  required
                />
              </validation-provider>

              <template
                v-if="createTemplate.documentType"
              >
                <v-select
                  v-model="createTemplate.cloneId"
                  :items="cloneVersionSelectItems"
                  label="Create New Template From Existing Document Template Version?"
                  :clearable="true"
                  required
                  class="mt-10"
                />
              </template>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    type="submit"
                    block
                    data-test="create-new-dialog-button"
                    :disabled="invalid"
                    v-on="on"
                  >
                    Create new document template
                    <v-icon
                      dark
                      right
                    >
                      mdi-plus-circle
                    </v-icon>
                  </v-btn>
                </template>
                <span>Create New Document Template</span>
              </v-tooltip>
            </v-form>
          </validation-observer>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showDeleteDocumentTemplateDialog"
      max-width="340"
      @click:outside="deleteDocumentTemplateDialog()"
    >
      <v-card
        v-if="showDeleteDocumentTemplateDialog"
        data-test="delete-document-template-dialog"
        light
      >
        <v-card-title class="pa-0">
          <v-app-bar
            light
            flat
          >
            Delete Unpublished Template
          </v-app-bar>
        </v-card-title>

        <v-card-text class="pa-4">
          Are you sure you wish to delete this template?
        </v-card-text>

        <v-card-actions class="pa-4 pt-0">
          <v-spacer />

          <v-btn
            dark
            data-test="delete-document-template-dialog-cancel-button"
            @click="deleteDocumentTemplateDialog()"
          >
            No
          </v-btn>

          <v-btn
            dark
            data-test="delete-document-template-dialog-confirm-button"
            @click="deleteDocumentTemplate(deleteDocumentTemplateId)"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card>
      <v-toolbar
        flat
      >
        <v-toolbar-title>
          Document Templates
        </v-toolbar-title>

        <div class="flex-grow-1" />

        <v-toolbar-title>
          <v-row>
            <v-col>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    data-test="create-new-button"
                    v-on="on"
                    @click.stop="openCloseCreateDialog()"
                  >
                    Create new
                    <v-icon
                      dark
                      right
                    >
                      mdi-plus-circle
                    </v-icon>
                  </v-btn>
                </template>
                <span>Create New Template</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-toolbar-title>
      </v-toolbar>

      <v-row>
        <v-col class="px-3 py-0">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            hide-details
            filled
          />
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="rows"
        :items-per-page="20"
        :sort-by="['documentType', 'localeText', 'version']"
        :sort-desc="[false, false, true]"
        multi-sort
        :footer-props="footerProps"
        :fixed-header="true"
        :search="search"
        item-key="id"
        data-test="table"
      >
        <template v-slot:item.isActive="{ item }">
          <v-tooltip
            v-if="item.isActive"
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-icon
                data-test="active-item-icon"
                v-on="on"
              >
                mdi-eye
              </v-icon>
            </template>
            <span>Template Active</span>
          </v-tooltip>

          <v-tooltip
            v-else
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-icon
                data-test="not-active-item-icon"
                v-on="on"
              >
                mdi-eye-off
              </v-icon>
            </template>
            <span>Template Not Active</span>
          </v-tooltip>
        </template>

        <template v-slot:item.action="{ item }">
          <div class="nowrap">
            <v-tooltip
              v-if="!isDevelopmentEnvironment && item.isPublished"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  fab
                  small
                  icon
                  data-test="view-item-button"
                  v-on="on"
                  @click.stop="navigateToEditor(item.id)"
                >
                  <v-icon>
                    mdi-file-document
                  </v-icon>
                </v-btn>
              </template>
              <span>View Template</span>
            </v-tooltip>

            <v-tooltip v-else bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  fab
                  small
                  icon
                  data-test="edit-item-button"
                  v-on="on"
                  @click.stop="navigateToEditor(item.id)"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit Template</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  fab
                  small
                  icon
                  data-test="clone-item-button"
                  v-on="on"
                  @click.stop="openCloseCreateDialog({
                    cloneId: item.id,
                    documentType: item.documentType,
                    locale: item.locale
                  })"
                >
                  <v-icon>
                    mdi-content-copy
                  </v-icon>
                </v-btn>
              </template>
              <span>Clone Template</span>
            </v-tooltip>

            <v-tooltip
              v-if="item.isPublished"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  class="mx-2"
                  data-test="block-item-icon"
                  v-on="on"
                >
                  mdi-block-helper
                </v-icon>
              </template>
              <span>No Delete Available</span>
            </v-tooltip>

            <v-tooltip
              v-else
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  fab
                  small
                  icon
                  data-test="delete-item-button"
                  v-on="on"
                  @click.stop="deleteDocumentTemplateDialog(item.id)"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Delete Template</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import isoCodes from '../utils/isoCodes'
import { cloneDeep } from 'lodash'
import { documentTemplateTypeLookup, encodeTemplateString } from '../utils'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

const { VUE_APP_ENVIRONMENT } = process.env

export default {
  name: 'DocumentTemplatesTable',

  components: {
    ValidationObserver,
    ValidationProvider
  },

  data () {
    return {
      showCreateDocumentTemplateDialog: false,
      createTemplate: {
        documentType: '',
        locale: '',
        cloneId: null
      },
      showDeleteDocumentTemplateDialog: false,
      deleteDocumentTemplateId: null,
      search: '',
      footerProps: {
        'items-per-page-options': [10, 20, 30, 40, 50, -1]
      },
      headers: [
        { text: 'Document Type', value: 'documentTypeText', align: 'left' },
        { text: 'Locale', value: 'localeText', align: 'left' },
        { text: 'Version', value: 'version', align: 'left' },
        { text: 'Published', value: 'publishedText', align: 'left' },
        { text: 'Active', value: 'isActive', align: 'left' },
        { text: 'Actions', value: 'action', align: 'left', sortable: false }
      ],
      isoCodeLookup: isoCodes
        .reduce((accumulator, currentValue) => {
          accumulator[currentValue.isoCode] = currentValue.name
          return accumulator
        }, {}),
      isoCodeSelectItems: isoCodes
        .map(({ isoCode, name }) => ({
          text: `${isoCode} - ${name}`,
          value: isoCode
        })),
      documentTemplateTypeSelectItems: Object.keys(documentTemplateTypeLookup)
        .map(key => ({
          text: documentTemplateTypeLookup[key],
          value: key
        }))
    }
  },

  computed: {
    isDevelopmentEnvironment () {
      return VUE_APP_ENVIRONMENT === 'development'
    },

    rows () {
      return cloneDeep(this.$store.getters['documentTemplates/getObjects'])
        .map(({ id, documentType, locale, version, isPublished, isActive, timestamp }) => ({
          id,
          documentType,
          documentTypeText: documentTemplateTypeLookup[documentType],
          locale,
          localeText: `${locale} - ${this.isoCodeLookup[locale]}`,
          version,
          isPublished,
          publishedText: isPublished ? 'Yes' : 'No',
          isActive,
          timestamp
        }))
    },

    cloneVersionSelectItems () {
      return this.createTemplate.documentType
        ? cloneDeep(this.$store.getters['documentTemplates/getObjects'])
            .filter(({ id, documentType, locale }) => this.createTemplate.documentType === documentType)
            .map(({ id, documentType, locale, version, isPublished }) => ({
              text: `${documentTemplateTypeLookup[documentType]} - ${locale} - ${version}`,
              value: id
            }))
        : []
    }
  },

  async created () {
    try {
      const documentTemplatesApiResult = await this.$store.dispatch('documentTemplates/getTemplateListFromTmsApi')

      this.$store.commit('setStateValue', {
        key: 'documentTemplates',
        value: documentTemplatesApiResult
      })
    } catch (error) {
      console.log(error)

      throw error
    }
  },

  methods: {
    getNewVersionNumber ({ documentType, locale }) {
      if (!documentType) throw new Error('Document Type missing')
      if (!locale) throw new Error('Locale missing')

      const currentHighestVersion = this.$store.getters['documentTemplates/getObjects']
        .filter(template => {
          return (template.documentType === documentType &&
            template.locale === locale)
        })
        .sort((a, b) => b.version - a.version)
        .shift()

      return (currentHighestVersion && Number(currentHighestVersion.version) + 1) || 1
    },

    async putNewDocumentTemplateToTms ({ documentType, locale, template }) {
      try {
        const newVersion = this.getNewVersionNumber({ documentType, locale })

        const id = `${documentType}:${locale}:${newVersion}`
        const newDocumentTemplate = {
          id,
          documentType,
          locale,
          version: newVersion,
          template: encodeTemplateString(template),
          userId: this.$store.state.userId,
          isPublished: false,
          isActive: false
        }

        this.$store.commit('documentTemplates/updateState', newDocumentTemplate)
        await this.$store.dispatch('documentTemplates/setObjectToApi', { id, object: newDocumentTemplate })

        return id
      } catch (error) {
        console.log(error)
        throw error
      }
    },

    async createNewTemplate () {
      try {
        if (!this.createTemplate.documentType) throw new Error('Document Type missing')
        if (!this.createTemplate.locale) throw new Error('Locale missing')

        const documentType = this.createTemplate.documentType
        const locale = this.createTemplate.locale
        const template = this.createTemplate.cloneId
          ? this.$store.getters['documentTemplates/getObject'](this.createTemplate.cloneId).template
          : ''

        const id = await this.putNewDocumentTemplateToTms({ documentType, locale, template })

        this.createTemplate = {
          documentType: '',
          locale: '',
          cloneId: null
        }

        this.navigateToEditor(id)
      } catch (error) {
        console.log(error)
      }
    },

    deleteDocumentTemplateDialog (id = null) {
      this.deleteDocumentTemplateId = id
      this.showDeleteDocumentTemplateDialog = !this.showDeleteDocumentTemplateDialog
    },

    async deleteDocumentTemplate (id = null) {
      try {
        if (!id) throw new Error('Id of template missing to delete')

        this.deleteDocumentTemplateDialog()

        await this.$store.dispatch('documentTemplates/deleteObjectFromApi', { id })

        this.$store.commit('documentTemplates/removeObjectFromState', id)
      } catch (error) {
        console.log(error)
      }
    },

    openCloseCreateDialog ({ documentType, locale, cloneId } = {
      documentType: '',
      locale: '',
      cloneId: null
    }) {
      this.createTemplate = {
        documentType,
        locale,
        cloneId
      }
      this.showCreateDocumentTemplateDialog = !this.showCreateDocumentTemplateDialog
    },

    navigateToEditor (id) {
      this.$router.push({
        name: 'DocumentTemplateEditor',
        params: { id }
      })
    }
  }
}

</script>
