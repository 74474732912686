<template>
  <v-app>
    <app-bar
      v-if="isLoggedIn"
      :avatar="avatar"
      :nickname="nickname"
    />

    <v-content>
      <v-container>
        <router-view
          v-if="isLoggedIn"
          :key="$route.fullPath"
        />

        <login-box v-else />
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import AppBar from './components/AppBar'
import LoginBox from './components/LoginBox'
import { getUserDetailsFromJwt } from './utils'

export default {
  name: 'App',

  components: {
    AppBar,
    LoginBox
  },

  data () {
    return {
      avatar: null,
      nickname: null
    }
  },

  computed: {
    isLoggedIn () {
      return this.$store.getters.isLoggedIn
    }
  },

  created () {
    if (!this.$store.state.userId) {
      const jwt = sessionStorage.getItem('jwt')

      if (jwt) {
        try {
          const { userId, userRole } = getUserDetailsFromJwt(jwt)

          this.$store.commit('setUser', { userId, userRole })
          return
        } catch (error) {
          console.log(error)
        }

        this.handleLogout()
        return
      }
    }

    this.showRouterView = true
  },

  methods: {
    async handleLogout () {
      sessionStorage.removeItem('jwt')
      this.$store.commit('unsetUser')
    }
  }
}
</script>
