<template>
  <v-layout>
    <v-row>
      <v-col>
        <template-editor
          v-if="renderEditor"
          :document-template="documentTemplate"
          @submitted="handleUpdate"
        />
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
import TemplateEditor from '../components/TemplateEditor.vue'
import attemptApiCall from '../utils/attemptApiCall'
import makeTmsGetRequest from '../apis/tms/requests/makeTmsGetRequest'
import { decodeTemplateString, encodeTemplateString } from '../utils'

export default {
  name: 'DocumentTemplateEditor',

  components: {
    TemplateEditor
  },

  props: {
    id: {
      type: String,
      default: ''
    }
  },

  data () {
    const [type, locale, version] = this.id.split(':')

    return {
      renderEditor: false,
      documentTemplate: {
        id: this.id,
        type,
        locale,
        version,
        userId: '',
        template: '',
        isPublished: false,
        isActive: false
      },
      type,
      locale,
      version
    }
  },

  async created () {
    try {
      const { data } = await attemptApiCall(
        makeTmsGetRequest,
        {
          jwt: sessionStorage.getItem('jwt'),
          type: this.type,
          locale: this.locale,
          version: this.version
        }
      )

      this.documentTemplate = {
        ...data,
        template: decodeTemplateString(data.template)
      }

      this.renderEditor = true
    } catch (err) {
      console.log(err)
    }
  },

  methods: {
    async handleUpdate (updatedDocumentTemplate) {
      try {
        if (updatedDocumentTemplate) {
          await this.$store.dispatch(
            'documentTemplates/setObjectToApi',
            {
              id: this.id,
              object: {
                ...updatedDocumentTemplate,
                template: encodeTemplateString(updatedDocumentTemplate.template)
              }
            }
          )

          this.$store.commit('documentTemplates/updateState', updatedDocumentTemplate)
        }

        this.$router.push({ path: '/document-templates' })
      } catch (err) {
        console.log(err)
      }
    }
  }
}
</script>
