'use strict'

export default async function myself (apiFunction, paramObject, tries = 1) {
  try {
    if (tries <= 3) {
      return apiFunction(paramObject)
    }
  } catch (error) {
    if (tries === 3) throw error

    await myself(apiFunction, paramObject, ++tries)
  }
}
