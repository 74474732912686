import Vue from 'vue'
import VueRouter from 'vue-router'
import DocumentTemplateEditor from '../views/DocumentTemplateEditor'
import DocumentTemplates from '../views/DocumentTemplates'

Vue.use(VueRouter)

const routes = [
  {
    path: '/document-templates',
    name: 'DocumentTemplates',
    component: DocumentTemplates,
    meta: { title: 'Document Templates' }
  },

  {
    path: '/document-templates/:id/editor',
    name: 'DocumentTemplateEditor',
    component: DocumentTemplateEditor,
    meta: { title: 'Document Template Editor' },
    props: true
  },

  {
    path: '*',
    redirect: '/document-templates'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
