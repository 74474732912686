import App from './App.vue'
import Vue from 'vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './veeValidate'
import * as Sentry from '@sentry/vue'

const { VUE_APP_ENVIRONMENT } = process.env

Vue.config.productionTip = false

Vue.mixin({
  computed: {
    showErrorComponent () {
      return !!this.$store.state.errorCode
    }
  }
})

Sentry.init({
  Vue,
  dsn: 'https://a487fa5dd1484b998096532accd5f4e4@o508066.ingest.sentry.io/5603694',
  environment: VUE_APP_ENVIRONMENT,
  autoSessionTracking: true,
  tracesSampleRate: 1.0,
  logErrors: false
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
